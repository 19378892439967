var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"feb-14:9fcbb94"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// @ts-nocheck
/* globals SENTRY_DSN */
/* globals BUILD_ID */
/* globals process */
/* globals WITH_MOCKER  */

import * as FullStory from '@fullstory/browser';
import * as Sentry from '@sentry/nextjs';
import { Integrations } from '@sentry/tracing';
import { timestampInSeconds } from '@sentry/utils';

import { getIsIntegrationTests, isBrowser, sessionStorage } from '@grid-is/browser-utils';
import { getConfig } from '@grid-is/environment-config';
import { mapSlugToRoute } from '@grid-is/tracking';

// How the Fullstory browser SDK evaluates if fullstory is running
const isFullstoryLoaded = () => isBrowser() && !!window[window._fs_namespace];

function getApiHost () {
  return `${location.hostname}/api`;
}

const samplingRate = () => {
  if (sessionStorage.perfSamplingRate) {
    const rate = parseFloat(sessionStorage.perfSamplingRate);
    if (!isNaN(rate) && rate >= 0 && rate <= 1) {
      return rate;
    }
  }
  return 0.1;
};

const eventUrl = 'https://sentry.io/organizations/grid/issues/?project=grid-client';

//* Snippet borrowed & adjusted from the Sentry/Fullstory SDK (https://github.com/getsentry/sentry-fullstory).
// Returns the sentry URL of the error
// If we cannot get the URL, return a string saying we cannot
const getSentryUrl = event => {
  try {
    const { dsn } =
        Sentry.getCurrentHub().getClient()
          ?.getOptions() || {};
    if (!dsn) {
      const msg = 'Sentry-Fullstory integration failed: no Sentry DSN found';
      console.warn(msg);
      return msg;
    }
    if (!event?.event_id) {
      const msg = 'Sentry-Fullstory integration failed: no event ID found';
      console.warn(msg);
      return msg;
    }
    return `${eventUrl}&query=${event.event_id}`;
  }
  catch (err) {
    const msg = `Sentry-Fullstory integration failed: ${err}`;
    console.warn(msg);
    return msg;
  }
};

//* Snippet borrowed & adjusted from the Sentry/Fullstory SDK (https://github.com/getsentry/sentry-fullstory).
// That SDK is set up as an Sentry Integration. Unfortunately we can only set up an integration by binding a new
// Sentry client. So instead of using that SDK directly, we just borrow and adjust this snippet here.
const integrateWithFullstory = event => {
  try {
    // Run the integration ONLY when it was installed on the current Hub
    if (isFullstoryLoaded()) {
      // getCurrentSessionURL isn't available until after the FullStory script is fully bootstrapped.
      // If an error occurs before getCurrentSessionURL is ready, make a note in Sentry and move on.
      // More on getCurrentSessionURL here: https://help.fullstory.com/develop-js/getcurrentsessionurl
      event.contexts = {
        ...event.contexts,
        fullStory: {
          fullStoryUrl:
              FullStory.getCurrentSessionURL(true) ||
              'current session URL API not ready',
        },
      };
      // FS.event is immediately ready even if FullStory isn't fully bootstrapped
      FullStory.event('Sentry Error', {
        sentryUrl: getSentryUrl(event),
      });
    }
  }
  catch (e) {
    // ignore catch
  }
};

// In rare cases we might want to ignore certain errors.
const ignoreErrors = [
  // Simply means that the the observer was not able to deliver all observations in a single animation frame.
  // Harmless and does not break site.
  'ResizeObserver loop limit exceeded',
  'ResizeObserver loop completed with undelivered notifications.',
  'TypeError: Failed to fetch',
  'NetworkError:  A network error occurred.',
  'NetworkError when attempting to fetch resource.',
  'TypeError: Failed to fetch',
  'TypeError: NetworkError when attempting to fetch resource.',
  'ChunkLoadError',
  'UnhandledRejection: Non-Error promise rejection captured with value: Object Not Found Matching Id:',
];

const isEnabled = process.env.NODE_ENV === 'production' && !WITH_MOCKER && process.env.NEXT_PUBLIC_DISABLE_TRACKING !== 'true' && !getIsIntegrationTests();
const config = getConfig();

const TRANSACTIONS_TO_IGNORE_IF_SHORT = [ 'Model.write', 'Model.add', 'Document.props.onModelChange' ];
const TRANSACTION_DURATION_THRESHOLD_IN_SECONDS = 0.04;

Sentry.init({
  enabled: isEnabled,
  dsn: SENTRY_DSN,
  environment: config.SENTRY_ENV,
  release: BUILD_ID,
  ignoreErrors,
  beforeSendTransaction: event => {
    if (event.start_timestamp && TRANSACTIONS_TO_IGNORE_IF_SHORT.includes(event.transaction)) {
      const elapsed = (event.timestamp || timestampInSeconds()) - event.start_timestamp;
      if (elapsed < TRANSACTION_DURATION_THRESHOLD_IN_SECONDS) {
        return null;
      }
    }
    return event;
  },
  beforeSend: event => {
    const { type, title, contexts, request } = event;
    if (request?.url?.match(/^file:/i)) {
      return null;
    }

    if (event.level === 'error' && event.exception && request) {
      const err = event.exception?.values?.[0];
      if (err && err.type === 'AuthorizationRequiredError') {
        // We rely on a mechanism where 401 exceptions are thrown when they occur
        // and caught and dealt with by ErrorBoundary. There is no reason to tell
        // Sentry about every occurrence.
        return null;
      }
    }

    if (
      type === 'error' &&
      /^(?:iPhone|Mac|iPad)$/.test(contexts?.device?.family || '') &&
      /^TypeError: (?:cancelled|geannuleerd|cancelado|annulleret|avbrutt|avbruten|Abgebrochen)$/i.test(title || '')
    ) {
      return null;
    }

    integrateWithFullstory(event);
    return event;
  },
  integrations:  [
    new Integrations.BrowserTracing({
      tracingOrigins: [ getApiHost() ],
      beforeNavigate: context => {
        const dynamicRouteName = mapSlugToRoute(window.location.pathname);
        if (dynamicRouteName) {
          return {
            ...context, name: dynamicRouteName,
          };
        }
        return context;
      },
    }),
  ]
    .filter(Boolean),
  //! The trace sample rate is the percentage of requests which are traced.
  //! we need to be careful to not set this to high as it affects our sentry quota
  tracesSampler: samplingRate,
});
