import * as Sentry from '@sentry/nextjs';

/**
 * Instrument an Apiary model for Sentry tracing, wrapping the write and add methods to
 * record them in a transaction (or a child span of an existing transaction).
 * @param {import('@grid-is/apiary').Model} model the model instance to instrument.
 * @return {import('@grid-is/apiary').Model} the same model instance, having had its write and add methods replaced by wrappers.
 */
export const instrumentForTracing = model => {
  const innerWrite = model.write.bind(model);
  // Send Model.write transactions only if they take at least 40 milliseconds
  /** @param {Parameters<typeof model.write>} args */
  model.write = function instrumentedWrite (...args) {
    Sentry.startSpan({ name: 'Model.write', op: 'function' }, () => innerWrite(...args));
  };
  const innerWriteMultiple = model.writeMultiple.bind(model);
  /** @param {Parameters<typeof model.writeMultiple>} args */
  model.writeMultiple = function instrumentedWriteMultiple (...args) {
    // Perf-trace with the same name as single write (as this is really measuring the recalculation and doc update)
    Sentry.startSpan({ name: 'Model.write', op: 'function' }, () => innerWriteMultiple(...args));
  };
  const innerAddWorkbook = model.addWorkbook.bind(model);
  /** @param {Parameters<typeof model.addWorkbook>} args */
  model.addWorkbook = function instrumentedAddWorkbook (...args) {
    const data = args[0];
    if (data.state === 'invalid') {
      // skip Sentry tracing when just adding a placeholder workbook
      return innerAddWorkbook(...args);
    }
    else {
      let wb = null;
      Sentry.startSpan({ name: 'Model.add', op: 'function' }, span => {
        wb = innerAddWorkbook(...args);
        const { sources, cellCount, volatileCount, graphNodes, graphEdges } =
          model.meta;
        const wbMeta = sources.find(s => s.id === wb.id);
        if (wbMeta) {
          span?.setAttributes({
            workbookName: wbMeta.name,
            workbookUpdateTime: wbMeta.update_time,
            workbookCellCount: wbMeta.cellCount,
            workbookVolatileCount: wbMeta.volatileCount,
          });
        }
        span?.setAttributes({
          modelCellCount: cellCount,
          modelVolatileCount: volatileCount,
          modelGraphNodes: graphNodes,
          modelGraphEdges: graphEdges,
        });
      });
      if (wb == null) {
        throw new Error(
          'Model.addWorkbook should have completed within Sentry.startSpan',
        );
      }
      return wb;
    }
  };
  return model;
};

export function logWarning (err) {
  Sentry.withScope(scope => {
    scope.setLevel('warning');
    Sentry.captureException(err);
  });
  console.error(err);
}
